import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "What The Hack is JWT ?!",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:57:28.000Z",
  "url": "/2018/02/what-the-hack-is-jwt/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`{.content-preview-title}`}</h1>
    <p>{`I was just writing on some of my coding adventures with some new awesome features I did and took advantage of JWT.`}</p>
    <p>{`While I was writing that post as I assumed everyone knows what is JWT, I decided to write a quick note about it. Just in case 🙂`}</p>
    <p>{`So in a brief JWT is a simple token that consists of 3 parts:`}</p>
    <ul>
      <li parentName="ul">{`headers`}</li>
      <li parentName="ul">{`payload`}</li>
      <li parentName="ul">{`signature`}</li>
    </ul>
    <p>{`example of a token:`}</p>
    <p><inlineCode parentName="p">{`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IlR6b29rIEJhciBOb3kiLCJ3ZWJzaXRlIjoiaHR0cHM6Ly90em9va2IuY29tIn0.79-chMwGpBzWhU-4oodSYy9ZBbbpjLRXQdjXPAmPrgc`}</inlineCode></p>
    <p>{`You can see it has a `}<inlineCode parentName="p">{`.`}</inlineCode>{` as a separator between the parts.`}</p>
    <h6>{`headers {#headers}`}</h6>
    <p>{`contains the algorithm used and the type.`}</p>
    <h6>{`payload {#payload}`}</h6>
    <p>{`contains all the data you want to transfer in that token.`}</p>
    <h6>{`signature {#signature}`}</h6>
    <p>{`is the has of the 2 above and the secret string we used to create the JWT.`}</p>
    <p>{`The JWT is not here to encrypt data, as it can easily get parsed and viewed by anyone. It is here to have the ability to transfer data and validate it got from a valid source.`}</p>
    <h6>{`Use Cases {#usecases}`}</h6>
    <p><a parentName="p" {...{
        "href": "http://tzookb.com/jwt-awesome-killer-use-cases/"
      }}>{`More Killer Use Cases`}</a></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can create a JWT with userId, expiration and send it to your frontend app. Now The app know which userId are you, and on all backend api calls, it will attach the JWT and in the backend we can verify the token. If the token is valid and not expired we can easily serve the request with the secured data it requested.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Forgot password. You can send your users a link with JWT and expiration. So when he clicks that, the token will be sent to the backend and if its valid, we can let the users change his passwords.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Auto Login. Send your users an email or sms with JWT and expiration and you could autologin them with a simple link click.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      